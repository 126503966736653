.inbox-threads-search {
  display: flex;
  align-items: center;
  border-radius: var(--border-input);
  border: 1px solid var(--border);
  outline: 0px;
  padding: 0px 8px;
  font-size: 14px;
  transition: border 0.12s var(--animation-beizer);
}
.inbox-threads-search:hover {
  border: 1px solid var(--border-hover);
}
.inbox-threads-search:focus-within {
  border: 1px solid var(--primary);
  outline: 4px solid var(--primary-pale);
}

.inbox-threads-search input {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
}
