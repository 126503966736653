.page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 38px;
}
.page-title div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
