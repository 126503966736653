.navigator {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  min-width: 224px;
  max-width: 224px;
  border: 1px solid var(--border);
  background-color: var(--paler);
  z-index: 50;
}

.navigator-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
  width: calc(100% - 16px);
}
.navigator-image {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  margin: 8px;
}
.navigator-header img {
  position: absolute;
  top: 3px;
  left: 2px;
  z-index: 1;
}
.navigator-header::after {
  content: '';
  position: absolute;
  top: 6px;
  left: 1px;
  width: 40px;
  height: 40px;
  background-color: var(--body);
  border-radius: 14px;
  z-index: 0;
}

.navigator-links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 8px;
  gap: 4px;
  width: calc(100% - 16px);
}
.navigator-link {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  gap: 6px;
  outline: 0px;
  border: 0px;
  border-radius: var(--border-input);
  background-color: transparent;
  width: calc(100% - 16px);
  cursor: pointer;
  transition: none !important;
}
.navigator-link:hover {
  background-color: var(--gray-lighter);
}
.navigator-link:active {
  background-color: var(--gray-light) !important;
}
.nl-active {
  background-color: var(--gray-light) !important;
}

.navigator-link-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--unread);
  margin-left: auto;
  margin-right: 4px;
}
.navigator-link-notification-small {
  position: absolute;
  top: -2px;
  right: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--unread);
}

.navigator-bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: auto;
  width: 100%;
}

.navigator-profile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 8px;
  margin-top: auto;
  width: calc(100% - 16px);
}
.navigator-profile-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 16px;
  background-color: var(--white);
  border-radius: var(--border-input);
  border: 1px solid var(--border);
  width: calc(100% - 32px);
  cursor: pointer;
}
.navigator-profile-info:hover {
  background-color: var(--paler);
}
.navigator-profile-info:active {
  background-color: var(--pale) !important;
}
.navigator-profile-info > :nth-child(1) img {
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid var(--border);
}
.navigator-profile-info > :nth-child(2) {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
}

.navigator-profile-info-avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}
.navigator-profile-info-avatar div {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  outline: 3px solid var(--white);
  z-index: 1;
}
.npia-connecting div {
  background-color: var(--warning);
}
.npia-connected div {
  background-color: var(--active);
}
.npia-disconnected div {
  background-color: var(--pale);
}

.navigator-small {
  min-width: 54px;
}
.navigator-small .navigator-header .navigator-expand-icon {
  position: absolute;
  right: -32px;
  background-color: var(--white);
  border-radius: 50%;
  border: 1px solid var(--border);
  box-shadow: var(--shadow);
  overflow: hidden;
  z-index: 200;
}
.navigator-profile-small {
  display: none;
}
.navigator-small .navigator-profile-info {
  display: none !important;
}
.navigator-small .navigator-profile-small {
  display: flex !important;
}

.navigator-link-hint {
  position: absolute;
  display: none;
  left: 56px;
  padding: 8px 16px;
  border-radius: var(--border-input);
  background-color: var(--body);
  z-index: 100;
}
.navigator-link-hint p {
  display: block;
  text-wrap: nowrap;
}
.navigator-link:hover .navigator-link-hint {
  display: flex;
}
