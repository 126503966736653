.inbox-conversation-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border);
  background-color: var(--white);
  padding: 12px 16px;
}

.inbox-conversation-header > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
