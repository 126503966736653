.plan-card-usage {
  flex: 2;
}
.plan-card-usage > .card {
  height: calc(100% - 32px);
}

.plan-usage-card__completion {
  height: 12px;
  width: 100%;
  background-color: var(--primary-pale);
  border-radius: var(--border-card);
  margin-top: 8px;
  margin-bottom: 6px;
}
.plan-usage-card__completion > div {
  height: 100%;
  border-radius: var(--border-card);
}
.plan-usage-card__completion__text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
}
