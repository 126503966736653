.template-select-step-scroll {
  max-height: 60vh;
  padding: 4px;
  overflow-y: auto;
}

.template-variables-step-container {
  display: flex;
  gap: 24px;
}

.template-variables-step-variables {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.template-variables-step-variables div {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}