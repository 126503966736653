.detail-view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.detail-view-header > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}
