.textarea {
  align-self: stretch;
  border-radius: var(--border-input);
  border: 1px solid var(--border);
  outline: 0px;
  padding: 8px 12px;
  margin-top: 8px;
  font-size: 14px;
  transition: border 0.12s var(--animation-beizer);
  height: 116px;
  resize: vertical;
}
.textarea:hover {
  border: 1px solid var(--border-hover);
}
.textarea:focus {
  border: 1px solid var(--primary);
  outline: 4px solid var(--primary-pale);
}

.textarea-error {
  border: 1px solid var(--error);
}
.textarea-error-message {
  font-size: 12px;
  color: var(--error);
  margin-top: 4px;
}

.textarea-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
  gap: 8px;
}
.textarea-row .textarea {
  flex: 1;
  margin: 0px !important;
  margin-top: 0px !important;
}
.textarea-row .button {
  margin: 0px !important;
  margin-top: 0px !important;
}
.textarea-row div {
  flex: 1;
  display: flex;
  flex-direction: column;
}
