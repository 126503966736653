.inbox-threads-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 153px);
}

.inbox-threads-body-scroll {
  overflow-y: scroll;
}
.inbox-threads-body-scroll::-webkit-scrollbar {
  display: none;
}
.inbox-threads-body-scroll {
  -ms-overflow-style: none;
  scrollbar-width: 1px;
}
