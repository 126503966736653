.campaign-config-card-sections {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.campaign-config-card-sections .button {
  margin-top: 12px !important;
  width: 200px !important;
}

.campaign-config-card-section__parameters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 8px;
}

.campaign-config-card-section__parameter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.campaign-config-card-section__parameter > .icon {
  background-color: var(--pale) !important;
  border-radius: var(--border-input) !important;
  padding: 2px !important;
}

.campaign-config-card-section__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.campaign-config-card-status {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 4px solid var(--error-pale);
  background-color: var(--error);
}
