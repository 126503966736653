.role-creator-tag {
  padding: 6px 8px;
  border-radius: var(--border-input);
  background-color: var(--gray-lighter);
  font-size: 12px;
  width: fit-content;
  height: fit-content;
}
.role-creator-tag p {
  margin-top: -4px;
  margin-bottom: -3px;
}

.rct-system {
  background-color: var(--primary-pale) !important;
  color: var(--primary);
}

.role-creator-tag-large {
  height: fit-content;
  padding: 6px 10px;
  border-radius: var(--border-input);
  background-color: var(--gray-lighter);
}
.role-creator-tag-large p {
  margin-top: -3px;
  margin-bottom: -3px;
}