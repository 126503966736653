.settings-fields {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.settings-fields > :nth-child(1) {
  border-top: 0px !important;
  margin-top: -16px !important;
}

.settings-field {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 16px;
  padding-bottom: 32px;
  border-top: 1px solid var(--border);
  gap: 64px;
}
@media only screen and (max-width: 1024px) {
  .settings-field {
    flex-direction: column !important;
    gap: 8px !important;
  }
  .settings-field-component {
    margin-top: 8px !important;
  }
}

.settings-field-label {
  flex: 2;
  min-width: 180px;
  max-width: 360px;
  font-size: 16px;
  font-weight: 500;
  color: var(--body);
}
.settings-field-label > :nth-child(1) {
  margin-bottom: 4px;
}

.settings-field-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.settings-field-component {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* border-radius: var(--border-input) var(--border-input) 0px 0px;
  overflow: hidden; */
}
.settings-field-component input {
  margin: 0px !important;
}

.settings-field-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
