.inbox-details-enduser {
  display: flex;
  flex-direction: column;
  margin: 8px;
  padding: 8px;
  background-color: var(--pale);
  border-radius: var(--border-input);
}

.inbox-details-enduser-card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 12px;
  background-color: var(--white);
  border-radius: var(--border-input);
  gap: 2px;
}
