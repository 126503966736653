.inbox-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
}

.inbox-tag {
  position: relative;
}
