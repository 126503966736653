.knowledge-source-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 16px;
}

.knowledge-source-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: var(--border-card);
  border: 1px solid var(--border);
  background-color: var(--white);
  gap: 16px;
  padding: 16px;
  height: 120px;
  min-width: 320px;
  cursor: pointer;
  overflow: hidden;
}
.knowledge-source-card:hover {
  border: 1px solid var(--border-hover);
}

.knowledge-source-card__body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.knowledge-source-card__add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100%);
  transition: none !important;
}
