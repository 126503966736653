.integration-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: var(--border-card);
  border: 1px solid var(--border);
  background-color: var(--white);
  padding: 16px;
  height: 88px;
  min-width: 212px;
  cursor: pointer;
  overflow: hidden;
}
.integration-card__add__card:hover {
  border: 1px solid var(--border-hover);
}

.integration-card__header {
  display: flex;
  align-items: stretch;
  gap: 8px;
}
.integration-card_header_icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(196, 224, 255);
  background: linear-gradient(
    180deg,
    rgba(196, 224, 255, 1) 0%,
    rgba(101, 164, 255, 1) 100%
  );
  border-radius: 8px;
}
.integration-card__header__text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.integration-card__id {
  background-color: var(--pale);
  padding: 2px 8px;
  border-radius: 8px;
  overflow-x: auto;
}

.integration-card__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: -16px;
  gap: 8px;
}

.integration-card__add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100%);
  transition: none !important;
}
