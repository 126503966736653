.PhoneInput {
  align-self: stretch;
  border-radius: var(--border-input);
  border: 1px solid var(--border);
  outline: 0px;
  padding: 8px 12px;
  margin-top: 8px;
  font-size: 14px;
  transition: border 0.12s var(--animation-beizer);
  gap: 4px;
}
.PhoneInput:hover {
  border: 1px solid var(--border-hover);
}
.PhoneInput:focus-within {
  border: 1px solid var(--primary);
  outline: 4px solid var(--primary-pale);
}
.PhoneInput:disabled {
  background-color: var(--paler);
}
.PhoneInput input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 14px;
}

.PhoneInputCountryIcon {
  width: 18px;
  height: 14px;
}
.PhoneInputCountryIcon--border {
  background-color: transparent !important;
  box-shadow: none !important;
}

.PhoneInput-error {
  border: 1px solid var(--error);
}
.PhoneInput-error-message {
  font-size: 12px;
  color: var(--error);
  margin-top: 4px;
}

.PhoneInputCountry {
  gap: 4px;
}
