.notification-sidebar-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--opacity);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: fadeIn 0.08s ease-in-out;
  z-index: 30;
}

.notification-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  background-color: var(--white);
  box-shadow: var(--shadow);
  animation: slideInLeft 0.12s ease-in-out;
  z-index: 100;
}

.notification-sidebar-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.notification-sidebar-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 4px;
}

.notification-sidebar-content-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 100%;
}
