.inbox-thread-skeleton {
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  min-height: 124px;
  max-height: 124px;
  border-bottom: 1px solid var(--border);
}

.inbox-thread-skeleton-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.inbox-thread-skeleton-picture {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin: 8px;
  margin-right: 4px;
}

.inbox-thread-skeleton-header-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 8px;
  margin-top: 6px;
  min-width: 50%;
}

.inbox-thread-skeleton-enduser {
  padding: 8px;
  margin-top: 6px;
  border-radius: var(--border-tag);
}

.inbox-thread-skeleton-chatbot {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-top: 2px;
  width: 60%;
  border-radius: var(--border-tag);
}

.inbox-thread-skeleton-header-updates {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 6px;
  margin: 8px;
}

.inbox-thread-skeleton-header-updates-time {
  padding: 5px 24px;
  border-radius: var(--border-tag);
}
.inbox-thread-skeleton-header-updates-unread {
  padding: 0px 12px;
  height: 16px;
  border-radius: var(--border-tag);
}

.inbox-thread-skeleton-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  width: 264px;
  margin-left: 8px;
  margin-right: 32px;
  margin-top: 4px;
}
.inbox-thread-skeleton-body div {
  border-radius: var(--border-tag);
  width: 100%;
  height: 14px;
}

.inbox-thread-skeleton-tags {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 24px;
  max-height: 24px;
  margin: 8px;
  margin-top: auto;
  gap: 4px;
  overflow-y: hidden;
  overflow-x: auto;
}

.inbox-thread-skeleton-tags div {
  border-radius: var(--border-tag);
  width: 64px;
  height: 20px;
}
