/* Helvetica Now Text */

@font-face {
  font-family: 'Helvetica Now Text';
  src:
    local('HelveticaNowText'),
    url(../../../assets/fonts/HelveticaNow/HelveticaNowText-Light.woff)
      format('woff');
  font-weight: 300;
}
@font-face {
  font-family: 'Helvetica Now Text';
  src:
    local('HelveticaNowText'),
    url(../../../assets/fonts/HelveticaNow/HelveticaNowText-Regular.woff)
      format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'Helvetica Now Text';
  src:
    local('HelveticaNowText'),
    url(../../../assets/fonts/HelveticaNow/HelveticaNowText-Medium.woff)
      format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'Helvetica Now Text';
  src:
    local('HelveticaNowText'),
    url(../../../assets/fonts/HelveticaNow/HelveticaNowText-Bold.woff)
      format('woff');
  font-weight: 600;
}
@font-face {
  font-family: 'Helvetica Now Text';
  src:
    local('HelveticaNowText'),
    url(../../../assets/fonts/HelveticaNow/HelveticaNowText-ExtraBold.woff)
      format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'Helvetica Now Text';
  src:
    local('HelveticaNowText'),
    url(../../../assets/fonts/HelveticaNow/HelveticaNowText-Black.woff)
      format('woff');
  font-weight: 800;
}

/* Inter Tight */

@font-face {
  font-family: 'Inter Tight';
  src:
    local('InterTight'),
    url(../../../assets/fonts/InterTight/InterTight-Regular.ttf)
      format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Inter Tight';
  src:
    local('InterTight'),
    url(../../../assets/fonts/InterTight/InterTight-Medium.ttf)
      format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Inter Tight';
  src:
    local('InterTight'),
    url(../../../assets/fonts/InterTight/InterTight-SemiBold.ttf)
      format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Inter Tight';
  src:
    local('InterTight'),
    url(../../../assets/fonts/InterTight/InterTight-Bold.ttf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Inter Tight';
  src:
    local('InterTight'),
    url(../../../assets/fonts/InterTight/InterTight-ExtraBold.ttf)
      format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Inter Tight';
  src:
    local('InterTight'),
    url(../../../assets/fonts/InterTight/InterTight-Black.ttf)
      format('truetype');
  font-weight: 900;
}

* {
  font-family: 'Helvetica Now Text', sans-serif;
}

body {
  background-color: var(--white);
  color: var(--body);
  margin: 0px;
}

.h1 {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 40px;
  color: var(--body);
  margin: 0;
}
.h2 {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 36px;
  color: var(--body);
  margin: 0;
}
.h3 {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 32px;
  color: var(--body);
  margin: 0;
}
.h4 {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 28px;
  color: var(--body);
  margin: 0;
}
.h5 {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: var(--body);
  margin: 0;
}
.h6 {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: var(--body);
  margin: 0;
}
.h7 {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: var(--body);
  margin: 0;
}
.h8 {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: var(--body);
  margin: 0;
}

.s1 {
  font-family: 'Helvetica Now Text', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--gray);
  margin: 0;
  margin-top: 16px;
}
.s2 {
  font-family: 'Helvetica Now Text', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--gray);
  margin: 0;
  margin-top: 16px;
}

.b1 {
  font-family: 'Helvetica Now Text', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: normal !important;
  color: var(--body);
  margin: 0;
}
.b2 {
  font-family: 'Helvetica Now Text', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: normal !important;
  color: var(--body);
  margin: 0;
}
.b3 {
  font-family: 'Helvetica Now Text', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal !important;
  color: var(--body);
  margin: 0;
}
.label {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: var(--body);
  margin: 0;
  margin-bottom: 8px;
}

a {
  color: var(--hyperlink);
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}
