.inbox-thread {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 4px;
  min-height: 128px;
  max-height: 128px;
  border-bottom: 1px solid var(--border);
  cursor: pointer;
}
.inbox-thread:hover {
  background-color: var(--pale);
}
.inbox-thread-standalone {
  min-height: 104px;
  max-height: 104px;
  width: 100%;
}

.inbox-thread-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.inbox-thread-header-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 8px;
  margin-top: 6px;
}
.inbox-thread-header-info .b2 {
  margin-top: -2px;
}

.inbox-thread-chatbot {
  display: flex;
  align-items: center;
  margin-left: -4px;
}

.inbox-thread-header-updates {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 4px;
  margin: 8px;
  margin-bottom: 0px;
}

.inbox-thread-header-updates-unread {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--unread);
}

.inbox-thread-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  margin-left: 8px;
  margin-right: 32px;
  margin-top: -2px;
}
.inbox-thread-body-standalone {
  margin-right: 8px !important;
}
.inbox-thread-body .icon {
  margin-top: -2px;
}
.inbox-thread-body .b3 {
  flex: 1;
}

.inbox-thread-tags {
  display: flex;
  flex-direction: row;
  height: 20px;
  max-height: 20px;
  margin: 8px;
  margin-top: auto;
  gap: 4px;
  overflow-y: hidden;
  overflow-x: auto;
}
.inbox-thread-tags::-webkit-scrollbar {
  display: none;
}
.inbox-thread-tags {
  -ms-overflow-style: none;
  scrollbar-width: 1px;
}

.inbox-thread-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  padding-left: 4px;
  border-radius: var(--border-tag);
  white-space: nowrap;
  gap: 2px;
  cursor: default;
}
.inbox-thread-tag .icon {
  margin-top: -4px;
  margin-bottom: -4px;
}

.inbox-thread-active {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 2px;
  background-color: var(--primary);
}
