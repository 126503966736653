.input {
  align-self: stretch;
  border-radius: var(--border-input);
  border: 1px solid var(--border);
  outline: 0px;
  padding: 8px 12px;
  margin-top: 8px;
  font-size: 14px;
  transition: border 0.12s var(--animation-beizer);
}
.input:hover {
  border: 1px solid var(--border-hover);
}
.input:focus {
  border: 1px solid var(--primary);
  outline: 4px solid var(--primary-pale);
}
.input:disabled {
  background-color: var(--paler);
}

.input-error {
  border: 1px solid var(--error);
}
.input-error-message {
  font-size: 12px;
  color: var(--error);
  margin-top: 4px;
}

.input-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
  gap: 8px;
}
.input-row .input {
  flex: 1;
  margin: 0px !important;
  margin-top: 0px !important;
}
.input-row .button {
  margin: 0px !important;
  margin-top: 0px !important;
}
.input-row div {
  flex: 1;
  display: flex;
  flex-direction: column;
}
