.overview-general-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  height: 100%;
  max-width: 640px;
  gap: 16px;
}

.overview-general-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid var(--border);
  background-color: var(--white);
}

.overview-general-card-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.overview-general-card-item .icon {
  background-color: var(--primary-pale) !important;
  border-radius: var(--border-input);
  padding: 2px;
}

.overview-general-card-item-bottom {
  display: flex;
  align-items: center;
  gap: 8px;
}

.overview-radial {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* radial gradient from the top right */
  background: radial-gradient(
    circle at 90% -180px,
    #adceff 0%,
    transparent 60%
  );
}
