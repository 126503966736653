.chatbot-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 16px;
  margin-top: 24px;
}

.chatbot-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border-radius: var(--border-card);
  border: 1px solid var(--border);
  background-color: var(--white);
  padding: 16px;
  padding-bottom: 12px;
  cursor: pointer;
}
.chatbot-card:hover {
  border: 1px solid var(--border-hover);
}

.chatbot-card__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
}
.chatbot-card__header__image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid var(--border);
  overflow: hidden;
}
.chatbot-card__header__id {
  background-color: var(--pale);
  padding: 4px 8px;
  border-radius: 8px;
  overflow-x: auto;
}
.chatbot-card__header__info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
}

.chatbot-card__body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  margin-top: 12px;
}

.chatbot-card__body__active {
  display: flex;
  align-items: center;
  gap: 8px;
}
.chatbot-card__body__inactive {
  display: flex;
  align-items: center;
  gap: 8px;
}
.chatbot-card__body__active div {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 4px solid var(--success-pale);
  background-color: var(--success);
}
.chatbot-card__body__inactive div {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 4px solid var(--error-pale);
  background-color: var(--error);
}

.chatbot-card__body__integrations {
  display: flex;
  flex-direction: row;
  gap: 0px;
}
