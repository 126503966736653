.campaign-stats-cards-grid {
  display: flex;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.campaign-stats-cards-grid > div {
  flex: 1;
  padding: 16px !important;
}

.campaign-stat-card__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  margin-top: -4px;
  margin-left: -6px;
  margin-bottom: 8px;
}

.campaign-stat-card__body {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
