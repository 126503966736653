.tab-menu {
  display: flex;
  flex-direction: column;
}

.tab-menu-header {
  position: sticky;
  top: -32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--border);
  padding-top: 16px;
  background-color: var(--white);
  z-index: 10;
}
.tab-menu-header-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-bottom: 1px solid transparent;
  border-radius: var(--border-input) var(--border-input) 0px 0px;
  margin-bottom: -1px;
  gap: 2px;
  cursor: pointer;
}
.tab-menu-header-title-selected {
  border-bottom: 1px solid var(--body);
}
.tab-menu-header-title:hover {
  background-color: var(--paler);
}

.tab-menu-header-title-icon {
  margin-left: -6px;
}

.tab-content {
  display: none;
  padding: 24px 0px;
}
.tab-content-selected {
  display: flex !important;
  flex: 1;
  flex-direction: column;
  overflow: visible;
}
