.inbox-conversation-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-top: 1px solid var(--border);
  background-color: var(--white);
  padding: 8px;
  gap: 8px;
  height: 180px;
}

.inbox-conversation-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inbox-conversation-input-container {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.inbox-conversation-input-container textarea {
  width: 100%;
  height: calc(100% - 16px);
  padding: 8px;
  resize: none;
  margin: 0px;
}
