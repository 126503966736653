.audio-player-detailed {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.audio-player-detailed-waveform {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 16px);
  height: 56px;
}

.audio-player-detailed-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}

.audio-player-detailed-controls-left {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.audio-player-detailed-controls-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.loading {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
