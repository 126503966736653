.campaign-config-card-templates {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.campaign-config-card-template {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -2px;
  gap: 6px;
}

.campaign-config-card-template__icon {
  border-radius: 50%;
  padding: 0px;
}
