.call-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.call-details-metadata {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.call-details-metadata-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
