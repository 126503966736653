.inbox-message-system-call {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  min-width: 200px;
  padding: 12px 12px;
  padding-left: 12px;
  margin-top: 4px;
  border-radius: var(--border-card);
  gap: 8px;
}

.inbox-message-system-call-started {
  background-color: var(--white);
  border: 1px solid var(--border);
}
.inbox-message-system-call-ended {
  background-color: var(--white);
  border: 1px solid var(--border);
}

.inbox-message-system-call-icon {
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 50%;
}

.inbox-message-system-call-content {
  display: flex;
  align-items: center;
  gap: 8px;
}
