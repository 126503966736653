.inbox-threads-unread-filter {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: var(--border-input);
  background-color: var(--pale);
}

.inbox-threads-unread-filter div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: var(--border-input);
  cursor: pointer;
  z-index: 1;
}

.inbox-threads-unread-filter-toggle {
  position: absolute;
  width: calc(50% - 12px);
  height: calc(100% - 16px);
  background-color: var(--white);
  border-radius: var(--border-input);
  transition: transform 0.12s;
  z-index: 0;
}
