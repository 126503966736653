.role-permission-graph {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.role-permission-graph-bar {
  height: 16px;
  width: 16px;
  border-radius: 4px;
}
