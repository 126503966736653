.inbox-sidebar-inboxes {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px;
  margin-top: 0px;
}
.inbox-sidebar-inbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 6px;
  border-radius: var(--border-input);
  cursor: pointer;
}
.inbox-sidebar-inbox:hover {
  background-color: var(--paler);
}
.inbox-sidebar-inbox-active {
  background-color: var(--pale);
}
