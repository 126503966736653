.template-preview-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--pale);
  border-radius: var(--border-input);
  width: 240px;
  max-height: 300px;
  padding: 48px 24px;
  flex: 1;
}

.template-preview-card {
  display: flex;
  flex-direction: column;
  width: calc(100% - 16px);
  border: 1px solid var(--border);
  border-radius: var(--border-card);
  background-color: var(--white);
  box-shadow: var(--shadow);
  gap: 8px;
  padding: 8px;
}

.template-preview-card__header div {
  display: flex;
  background-color: var(--pale);
  border-radius: var(--border-card);
  overflow: hidden;
}
.template-preview-card__header div img {
  width: 100%;
  height: 120px;
  object-fit: cover;
}
.template-preview-card__header__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
}
.template-preview-card__header__file {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px;
}

.template-preview-card__body {
  padding: 2px;
}

.template-preview-card__footer {
  padding: 2px;
}

.template-preview-buttons {
  display: flex;
  flex-direction: column;
  width: calc(100%);
  margin-top: 6px;
  gap: 6px;
}

.template-preview-button {
  width: calc(100% - 12px);
  border: 1px solid var(--border);
  border-radius: var(--border-input);
  background-color: var(--white);
  box-shadow: var(--shadow);
  padding: 6px;
}
