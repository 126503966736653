.tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 4px;
  gap: 2px;
  border-radius: var(--border-tag);
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
}
.tag-text {
  margin-right: 4px;
}
.tag-border {
  border: 1px solid;
}
.tag.blue {
  background-color: var(--tag-blue-pale);
  border-color: var(--tag-blue);
  color: var(--tag-blue);
}
.tag.green {
  background-color: var(--tag-green-pale);
  border-color: var(--tag-green);
  color: var(--tag-green);
}
.tag.orange {
  background-color: var(--tag-orange-pale);
  border-color: var(--tag-orange);
  color: var(--tag-orange);
}
.tag.purple {
  background-color: var(--tag-purple-pale);
  border-color: var(--tag-purple);
  color: var(--tag-purple);
}
.tag.red {
  background-color: var(--tag-red-pale);
  border-color: var(--tag-red);
  color: var(--tag-red);
}
.tag.yellow {
  background-color: var(--tag-yellow-pale);
  border-color: var(--tag-yellow);
  color: var(--tag-yellow);
}
.tag.pink {
  background-color: var(--tag-pink-pale);
  border-color: var(--tag-pink);
  color: var(--tag-pink);
}
.tag.white {
  background-color: var(--tag-white-pale);
  border-color: var(--tag-white);
  color: var(--tag-white);
}

.tag-close {
  display: none;
}
.tag:hover .tag-close {
  display: flex;
}
.tag:hover .tag-icon {
  display: none;
}
.tag-hide {
  display: none;
}
