.audio-player {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  white-space: nowrap;
  gap: 8px;
}

.loading {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
