.template-card-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 140px;
  border: 1px solid var(--border);
  border-radius: var(--border-input);
  padding: 16px;
  overflow: hidden;
}

.template-card-skeleton__status {
  display: flex;
  align-items: center;
  gap: 6px;
}
.template-card-skeleton__status p {
  margin: 0px;
}

.template-card-skeleton__status_icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.template-card-skeleton__status_text {
  width: 64px;
  height: 12px;
  border-radius: var(--border-input);
}

.template-card-skeleton__header {
  display: flex;
  align-items: flex-start;
  height: 18px;
  width: 80%;
  border-radius: var(--border-input);
  margin-top: 10px;
  margin-bottom: 12px;
}

.template-card-skeleton__body {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.template-card-skeleton__body div {
  height: 12px;
  border-radius: var(--border-input);
}

.template-card-skeleton__footer {
  margin-top: auto;
  height: 10px;
  width: 60%;
  border-radius: var(--border-input);
}

