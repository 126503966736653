.inbox-message-template {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  gap: 8px;
}

.inbox-message-template__header div {
  display: flex;
  background-color: var(--pale);
  border-radius: var(--border-card);
  margin: -2px -6px;
  overflow: hidden;
}
.inbox-message-template__header div img {
  width: 100%;
  height: 120px;
  object-fit: cover;
}
.inbox-message-template__header__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
}
.inbox-message-template__header__file {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 24px;
  cursor: pointer;
}
.inbox-message-template__header__file:hover p {
  color: var(--primary) !important;
  text-decoration: underline;
}
.inbox-message-template svg {
  transition: none !important;
}
.inbox-message-template:hover svg {
  fill: var(--primary) !important;
}

.inbox-message-template__body {
  padding: 2px;
}

.inbox-message-template__footer {
  padding: 2px;
}

.inbox-message-template-buttons {
  display: flex;
  flex-direction: column;
  width: calc(100%);
  margin-top: 6px;
  margin-bottom: -4px;
}

.inbox-message-template-button {
  border-top: 0.1px solid var(--gray-2);
  padding: 8px;
}
