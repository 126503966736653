.inbox-message-document {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  padding: 4px 12px;
  padding-left: 8px;
  margin: -2px -6px;
  border-radius: var(--border-input);
  cursor: pointer;
}
.inbox-message-document:hover p {
  color: var(--primary) !important;
  text-decoration: underline;
}
.inbox-message-document svg {
  transition: none !important;
}
.inbox-message-document:hover svg {
  fill: var(--primary) !important;
}
