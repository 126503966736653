.overview-active-campaigns {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.overview-active-campaign {
  display: flex;
  flex-direction: column;
  padding: 8px;
  padding-top: 4px;
  border-radius: var(--border-input);
  gap: 8px;
}
.overview-active-campaign .icon {
  background-color: var(--primary-pale) !important;
  border-radius: var(--border-input);
  padding: 4px;
}
.overview-active-campaign-bot {
  border-radius: var(--border-tag);
  background-color: var(--pale);
  padding: 0px 6px;
  width: fit-content;
}

.overview-active-campaign:hover {
  background-color: var(--pale);
  cursor: pointer;
}

.overview-active-campaign__completion {
  height: 8px;
  width: 100%;
  background-color: var(--primary-pale);
  border-radius: var(--border-card);
}
.overview-active-campaign__completion > div {
  height: 100%;
  border-radius: var(--border-card);
}
