.card {
  border: 1px solid var(--border);
  border-radius: var(--border-card);
  padding: 16px;
}

.card-clickable:hover {
  cursor: pointer;
  border-color: var(--border-hover);
}
