.auth-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
@media only screen and (max-width: 768px) {
    .auth-container > :last-child {
        display: none !important;
    }
}
@media only screen and (max-width: 480px) {
    .auth-container > :first-child div {
        width: calc(100% - 48px) !important;
        max-width: 100% !important;
    }
}
.auth-container > :first-child {
    position: relative;
    flex: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.auth-container > :first-child div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: center;
    width: 420px;
    max-width: calc(100% - 112px);
}
.auth-container > :last-child {
    flex: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.auth-container > :last-child img {
    flex: 1;
    min-height: 100vh;
    aspect-ratio: 1;
}

.auth-logo {
    position: absolute;
    top: 24px;
    left: 24px;
}

.auth-confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.auth-confirm-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 200px 0px;
    text-align: center;
}
.auth-confirm-container p {
    max-width: 320px;
}
.auth-confirm-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--pale);
    padding: 8px;
    margin-bottom: 24px;
    border-radius: 50%;
    height: 48px;
    width: 48px;
}
.auth-confirm-disclaimer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    padding-bottom: 24px;
}