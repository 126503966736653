.select {
  align-self: stretch;
  border-radius: var(--border-input);
  border: 1px solid var(--border);
  outline: 0px;
  padding: 8px 12px;
  margin-top: 8px;
  font-size: 14px;
  transition: border 0.12s var(--animation-beizer);
  -webkit-appearance: none;
}
.select:hover {
  border: 1px solid var(--border-hover);
}
.select:focus {
  border: 1px solid var(--primary);
  outline: 4px solid var(--primary-pale);
}

.select-error {
  border: 1px solid var(--error);
}
.select-error-message {
  font-size: 12px;
  color: var(--error);
  margin-top: 4px;
}
