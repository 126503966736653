.campaign-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border-radius: var(--border-card);
  border: 1px solid var(--border);
  background-color: var(--white);
  padding: 16px;
  cursor: pointer;
}
.campaign-card:hover {
  border: 1px solid var(--border-hover);
}

.campaign-card.selectable {
  cursor: pointer;
  border: 1px dashed var(--border);
}
.campaign-card.selectable:hover {
  border: 1px dashed var(--border-hover);
}

.campaign-card.selected {
  border: 1px solid var(--primary);
  outline: 4px solid var(--primary-pale);
}
.campaign-card.selected:hover {
  border: 1px solid var(--primary-hover);
}

.campaign-card__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.campaign-card__header > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  margin-top: -16px;
}

.campaign-card__body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.campaign-card__completion {
  height: 8px;
  width: 100%;
  background-color: var(--pale);
  border-radius: var(--border-card);
  margin-top: 16px;
}
.campaign-card__completion > div {
  height: 100%;
  border-radius: var(--border-card);
}
.campaign-card__completion__text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
}

.campaign-card__bot {
  display: flex;
  align-items: center;
  background-color: var(--pale);
  padding: 4px 6px;
  padding-right: 10px;
  border-radius: var(--border-input);
  overflow-x: auto;
  margin-top: 12px;
}

.campaign-card__state {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 8px;
}
.campaign-card__state div {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 4px solid var(--error-pale);
  background-color: var(--error);
}

.campaign-card__selectable-indicator {
  position: absolute;
  top: 36px;
  right: 20px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid var(--border);
  background-color: var(--transparent);
}
.campaign-card__selectable-indicator.selected {
  border: 1px solid var(--primary);
  outline: 4px solid var(--primary-pale);
  background-color: var(--primary);
}
