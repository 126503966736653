.modal-foreground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--opacity);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: fadeIn 0.08s ease-in-out;
}
.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 420px;
  max-width: 480px;
  min-height: 100px;
  max-height: 80vh;
  padding: 24px 16px;
  padding-bottom: 16px;
  background-color: var(--white);
  border-radius: var(--border-input);
  box-shadow: var(--shadow);
  animation: hoverIn 0.12s ease-in-out;
  overflow: hidden;
}
.modal-large {
  max-width: 640px;
}
.modal-close {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: -6px;
  margin-bottom: -2px;
}
.modal-title-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-top: -8px;
  margin-bottom: -8px;
  background-color: var(--primary-pale);
}

.modal-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin-bottom: 24px;
  background-color: var(--primary-pale);
}

.modal-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
