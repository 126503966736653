.knowledge-chunk {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid var(--border);
  border-radius: 5px;
  background-color: var(--pale);
}
