.inbox-lists {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
}

.inbox-list {
  position: relative;
}
