.inbox-conversation-window-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  background-color: var(--pale);
  border-radius: var(--border-input);
  height: 100%;
  gap: 4px;
}

.inbox-conversation-window-message .icon {
  margin-left: -4px;
}
