.inbox-conversation-messages {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--pale);
  min-height: 0;
}

.inbox-conversation-messages-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  z-index: 5;
}

.inbox-conversation-messages-scroll {
  padding: 4px 16px;
  overflow-y: scroll;
}
.inbox-conversation-messages-scroll::-webkit-scrollbar {
  display: none;
}
.inbox-conversation-messages-scroll {
  -ms-overflow-style: none;
  scrollbar-width: 1px;
}
