.inbox-details-campaigns {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inbox-details-campaigns-item {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border);
  border-radius: var(--border-input);
  padding: 8px;
  gap: 4px;
  overflow: hidden;
  cursor: pointer;
}
.inbox-details-campaigns-item:hover {
  border: 1px solid var(--border-hover);
}

.inbox-details-campaigns-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
}
.inbox-details-campaigns-item-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
}

.inbox-details-campaigns-more {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4px;
  padding: 0px 4px;
}
