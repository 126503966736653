.inbox-message-container-AI {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}
.inbox-message-container-EU {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.inbox-message-container-SYS {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.inbox-message-container-last {
  margin-bottom: 6px;
}

.inbox-message {
  margin-bottom: 4px;
  padding: 8px 12px;
  max-width: 60%;
  border-radius: var(--border-card);
  border: 1px solid var(--border);
  white-space: pre-wrap;
  word-wrap: break-word;
}

.inbox-message-AI {
  background-color: var(--message);
}
.inbox-message-last-AI {
  border-radius: var(--border-card) var(--border-card) 0px var(--border-card);
}

.inbox-message-EU {
  align-self: flex-start;
  background-color: var(--white);
}
.inbox-message-last-EU {
  border-radius: var(--border-card) var(--border-card) var(--border-card) 0px;
}

.inbox-message-SYS {
  width: 100% !important;
  max-width: 100% !important;
  background-color: var(--transparent);
  padding: 0px !important;
  border: none !important;
}

.inbox-message-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}
.inbox-message-details .icon {
  margin: -6px 0px;
}
