.checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}
.toggle-label {
  font-size: 14px;
  color: var(--gray);
}

.checkbox .toggle {
  appearance: none;
  background-color: var(--gray);
  border-radius: 72px;
  border-style: none;
  border: 1px solid var(--border);
  flex-shrink: 0;
  height: 24px;
  margin: 0;
  position: relative;
  width: 40px;
}

.checkbox .toggle::before {
  bottom: -6px;
  content: '';
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.checkbox .toggle,
.checkbox .toggle::after {
  transition: all 100ms ease-out;
}

.checkbox .toggle::after {
  background-color: var(--white);
  border-radius: 50%;
  content: '';
  height: 18px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 18px;
}

.checkbox input[type='checkbox'] {
  cursor: pointer;
}

.checkbox .toggle:hover {
  background-color: var(--gray);
  transition-duration: 0s;
}

.checkbox .toggle:checked {
  background-color: var(--primary);
}

.checkbox .toggle:disabled {
  background-color: var(--gray-light) !important;
  cursor: not-allowed;
}
.checkbox .toggle:disabled:checked {
  background-color: var(--primary-pale) !important;
  cursor: not-allowed;
}

.checkbox .toggle:checked::after {
  background-color: var(--white);
  left: 18px;
}

.checkbox :focus:not(.focus-visible) {
  outline: 0;
}

.checkbox .toggle:checked:hover {
  background-color: var(--primary-hover);
}

/* Variant "expressive" */
.checkbox-expressive .toggle {
  background-color: var(--error) !important;
}
.checkbox-expressive .toggle:hover {
  background-color: var(--error) !important;
}
.checkbox-expressive .toggle:checked {
  background-color: var(--success) !important;
}
.checkbox-expressive .toggle:disabled {
  background-color: var(--error-pale) !important;
}
.checkbox-expressive .toggle:disabled:checked {
  background-color: var(--success-pale) !important;
}
.checkbox-expressive .toggle:checked:hover {
  background-color: var(--success) !important;
}
.checkbox-expressive .toggle:disabled:checked:hover {
  background-color: var(--success-pale) !important;
}
