.campaign-contacts-upload-example {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  border-top: 1px solid var(--border);
  border-left: 1px solid var(--border);
  margin-top: 16px;
}

.campaign-contacts-upload-example-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.campaign-contacts-upload-example-row:first-child {
  background-color: var(--pale);
}

.campaign-contacts-upload-example-row div {
  padding: 6px 12px;
  border-right: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
}
.campaign-contacts-upload-example-row div:nth-child(1) {
  flex: 1;
}
.campaign-contacts-upload-example-row div:nth-child(2) {
  flex: 1.5;
}
.campaign-contacts-upload-example-row div:nth-child(3) {
  flex: 2;
}
