.template-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 140px;
  border: 1px solid var(--border);
  border-radius: var(--border-input);
  padding: 16px;
  overflow: hidden;
}

.template-card__status {
  display: flex;
  align-items: center;
  gap: 6px;
}
.template-card__status p {
  margin: 0px;
}

.template-card__status_icon {
  border-radius: 50%;
}
.template-card__status_icon > .icon {
  margin: -2px;
}

.template-card__header {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  margin-top: 10px;
  margin-bottom: 6px;
}
.template-card__header > .icon {
  margin-left: -4px;
  margin-top: -4px;
  margin-bottom: -4px;
}

.template-card__footer {
  margin-top: auto;
}

.template-card__selected {
  border: 1px solid var(--primary);
  outline: 4px solid var(--primary-pale);
}
