.otp-container {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: start !important;
  gap: 8px;
  margin-top: 16px;
}

.otp-input {
  align-self: stretch;
  border-radius: var(--border-input);
  border: 1px solid var(--border);
  outline: 0px;
  padding: 8px;
  margin-top: 8px;
  font-size: 20px;
  font-family: 'Inter Tight', sans-serif;
  transition: border 0.12s var(--animation-beizer);
}
.otp-input:hover {
  border: 1px solid var(--border-hover);
}
.otp-input:focus {
  border: 1px solid var(--primary);
  outline: 4px solid var(--primary-pale);
}
.otp-input:disabled {
  background-color: var(--paler);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
