.overview-campaign-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.overview-campaign-summary-chart {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 16px;
}

.overview-campaign-summary-chart svg {
  margin-bottom: -6px;
}

.overview-campaign-summary-stats {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 32px;
}

.overview-campaign-summary-stat {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
}
.overview-campaign-summary-stat div {
  display: flex;
  align-items: center;
  gap: 6px;
}
.overview-campaign-summary-stat div > span {
  display: flex;
  width: 6px;
  height: 6px;
  border: 3px solid;
  border-radius: 50%;
}
