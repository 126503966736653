.campaign-call-answered-by {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  background-color: var(--pale);
  gap: 4px;
  padding: 2px 12px;
  padding-left: 8px;
  border-radius: var(--border-card);
}

.campaign-call-answered-by p {
  text-transform: capitalize;
}
