.notification {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
  border-radius: var(--border-input);
  gap: 12px;
  cursor: pointer;
}
.notification:hover {
  background-color: var(--pale);
}

.notification-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.notification-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
