.copy-input {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;
}

.copy-input button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border);
  border-radius: var(--border-input);
  background-color: var(--paler);
  padding: 8px;
  margin-top: 8px;
  cursor: pointer;
}
.copy-input button:hover {
  border: 1px solid var(--border-hover);
}

.copy-input__hint {
  display: none;
  position: absolute;
  width: 64px;
  background-color: var(--body);
  border-radius: var(--border-input);
  bottom: calc(100% + 8px);
  padding: 6px 0px;
  z-index: 100;
}
.copy-input__hint::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid var(--body);
}

.copy-input button:hover .copy-input__hint {
  display: block;
}
