.tag-input-container {
  display: flex;
  position: relative;
  width: 100%;
}

.tag-input {
  flex: 1;
  min-width: 0px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--border);
  border-radius: var(--border-input);
  padding: 8px;
  gap: 4px;
  overflow: show;
}

.tag-input-add {
  border: none;
  outline: none;
  background: none;
  padding: 4px;
  margin: 0;
  font-size: 12px;
}

.tag-input-suggestions {
  z-index: 100;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: var(--white);
  border: 1px solid var(--border);
  border-radius: var(--border-input);
  box-shadow: var(--shadow);
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
