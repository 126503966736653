.inbox-send-template {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  background-color: var(--pale);
  border: 1px solid var(--border);
  border-radius: var(--border-input);
  cursor: pointer;
}
.inbox-send-template:hover {
  border-color: var(--border-hover);
}
