.inbox-details-details {
  display: flex;
  flex-direction: column;
}

.inbox-details-item {
  display: flex;
  flex-direction: column;
  margin: 4px;
}
.inbox-details-item div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -2px;
  gap: 2px;
}
