.inbox-message-image-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.inbox-message-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% + 8px);
  max-width: 320px;
  max-height: 240px;
  border-radius: var(--border-card);
  margin: 0px -4px;
  overflow: hidden;
}

.inbox-message-image img {
  width: 100%;
  object-fit: contain;
  border-radius: var(--border-card);
  cursor: pointer;
}
