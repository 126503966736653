.sendable-media-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(196px, 1fr));
  grid-gap: 16px;
}

.sendable-media-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: var(--border-card);
  border: 1px solid var(--border);
  background-color: var(--white);
  gap: 16px;
  padding: 16px;
  height: 200px;
  min-width: 196px;
  cursor: pointer;
  overflow: hidden;
}
.sendable-media-card:hover {
  border: 1px solid var(--border-hover);
}

.sendable-media-card__image__container {
  height: 50%;
  margin: -16px -16px 0 -16px;
  border-bottom: 1px solid var(--border);
}
.sendable-media-card__image__container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sendable-media-card__body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sendable-media-card__add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100%);
  transition: none !important;
}
