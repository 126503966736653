.inbox-sidebar-selectable {
  display: flex;
  align-items: center;
  padding: 3px;
  gap: 8px;
  border-radius: var(--border-input);
  transition: none;
  cursor: pointer;
}
.inbox-sidebar-selectable .inbox-sidebar-selectable-box {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: var(--pale);
  border-radius: var(--border-tag);
}
.inbox-sidebar-selectable .inbox-sidebar-selectable-box .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.inbox-sidebar-selectable p {
  user-select: none;
}
.inbox-sidebar-selectable:hover {
  background-color: var(--pale);
}
