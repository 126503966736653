.button {
  position: relative;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: var(--border-input);
  height: 36px;
  border: 0px;
  outline: 0px;
  padding: 8px 16px;
  margin-top: 16px;
  cursor: pointer;
}

.button .icon {
  width: fit-content !important;
  margin-left: 6px !important;
}

.button-small {
  height: 32px;
  padding: 8px 16px;
}

.button-loader-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 48px);
  height: calc(100% - 16px);
}
.button-label-container {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px;
}
.button-label-container.hidden {
  opacity: 0;
}
.button-small .button-label-container {
  gap: 6px;
}

.button-primary {
  background-color: var(--primary);
}
.button-primary p {
  color: var(--white) !important;
}
.button-primary:hover {
  background-color: var(--primary-hover);
}
.button-primary:disabled {
  background-color: var(--border);
  cursor: not-allowed;
}
.button-secondary {
  background-color: var(--white);
  border: 1px solid var(--border);
}
.button-secondary p {
  color: var(--body) !important;
}
.button-secondary:hover {
  background-color: var(--border);
}
.button-secondary:disabled {
  cursor: not-allowed;
}
.button-secondary:disabled:hover {
  background-color: var(--white);
}
.button-secondary:disabled p {
  color: var(--gray-light) !important;
}
.button-tertiary {
  background-color: var(--transparent);
  border: 0px;
}
.button-tertiary p {
  color: var(--body) !important;
}
.button-tertiary:hover {
  background-color: var(--gray-lighter);
}
.button-tertiary:disabled {
  background-color: var(--gray-lighter);
  cursor: not-allowed;
}

.button-danger {
  background-color: var(--error);
}
.button-danger p {
  color: var(--white) !important;
}
.button-danger:hover {
  background-color: var(--error-hover);
}
.button-danger:disabled {
  background-color: var(--border);
  cursor: not-allowed;
}
