.file-input-drop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--border-input);
  border: 1px dashed var(--border);
  background-color: var(--white);
  padding: 16px;
  margin-top: 8px;
  cursor: pointer;
}
.file-input-drop:hover {
  border: 1px dashed var(--border-hover);
}

.file-input {
  max-width: 100%;
  padding: 5px;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid var(--border);
}
.file-input:hover {
  border: 1px solid var(--border-hover);
}
input[type='file'] {
  margin-top: 4px;
  font-size: 12px;
}
input[type='file']::file-selector-button {
  margin-right: 16px;
  font-size: 12px;
  background: var(--primary);
  padding: 6px 10px;
  border-radius: var(--border-input);
  color: var(--white);
  cursor: pointer;
  border: none;
}

.file-input-error-message {
  font-size: 12px;
  color: var(--error);
  margin-top: 4px;
}
