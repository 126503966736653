.hr {
  flex: 0 !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 16px !important;
  width: 100% !important;
  height: 16px !important;
  margin: 32px 0px;
}
.hr > :first-child,
.hr > :last-child {
  flex: 1;
  height: 1px !important;
  background-color: var(--border);
}
