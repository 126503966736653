.plan-card {
  flex: 1;
}
.plan-card > .card {
  height: calc(100% - 32px);
}

.plan-card-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.plan-card-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.plan-card-item__content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.plan-card-item .icon {
  background-color: var(--primary-pale) !important;
  border-radius: var(--border-input);
  padding: 2px;
}
