.inbox-details-assignees {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border);
  border-radius: var(--border-input);
  gap: 8px;
}

.inbox-details-assignee {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 12px;
  border-radius: var(--border-input);
  overflow: hidden;
  cursor: pointer;
}
.inbox-details-assignee:hover {
  border-color: var(--border-hover);
  background-color: var(--pale);
}

.inbox-details-assignee-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--pale);
}

.inbox-details-assign {
  z-index: 100;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: var(--border-input);
  box-shadow: var(--shadow);
  padding: 4px;
  gap: 4px;
  cursor: pointer;
}
