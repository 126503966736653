.sidebar-expandable {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0px 8px;
}

.sidebar-expandable-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 6px 0px;
  border-radius: var(--border-input);
  cursor: pointer;
}
.sidebar-expandable-header p {
  user-select: none;
}
.sidebar-expandable-header div {
  background-color: var(--pale);
  border-radius: var(--border-input);
}

.sidebar-expandable-elements {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 0px;
}

.sidebar-expandable-expanded .icon-rotate {
  transform: rotate(90deg);
}
.sidebar-expandable-expanded .sidebar-expandable-elements {
  max-height: 1000px;
  margin-bottom: 32px;
}
