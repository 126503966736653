.inbox-related-threads {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0px 8px;
  margin-bottom: 24px;
}

.inbox-related-thread {
  display: flex;
  align-items: center;
  border: 1px solid var(--border);
  border-radius: var(--border-input);
  gap: 8px;
  cursor: pointer;
  overflow: hidden;
}

.inbox-related-thread:hover {
  border-color: var(--border-hover);
  background-color: var(--pale);
}
