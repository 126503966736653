.contacts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contacts-header-left {
  display: flex;
  align-items: center;
  gap: 4px;
}

.contacts-header-right {
  display: flex;
  align-items: center;
  gap: 16px;
}
.contacts-header-right .button {
  margin-top: 0px;
}

.contacts-table {
  margin-top: 16px;
  margin-bottom: 32px;
}

.contact-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
