.campaign-scheduling-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
}

.campaign-scheduling-grid__day {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border);
  border-radius: var(--border-input);
  padding: 4px 8px;
  cursor: pointer;
}
.campaign-scheduling-grid__day:hover {
  border-color: var(--border-hover);
}

.campaign-scheduling-grid__day-active {
  border-color: var(--body);
}

.campaign-scheduling-modal-row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}
.campaign-scheduling-modal-row input {
  margin-top: 0px !important;
}
.campaign-scheduling-modal-row .checkbox {
  margin-left: auto;
}
