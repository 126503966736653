.campaign-call-status {
  display: flex;
  align-items: center;
  gap: 8px;
}

.campaign-call-status div {
  width: 8px;
  height: 8px;
  border: 3px solid;
  border-radius: 50%;
}

.campaign-call-status p {
  text-transform: capitalize;
}
