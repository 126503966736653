.inbox-sidebar-header-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.inbox-sidebar-header-status div {
  background-color: var(--warning);
  border: 4px solid var(--warning-pale);
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.inbox-sidebar-header-status-connecting div {
  background-color: var(--warning);
  border: 4px solid var(--warning-pale);
}
.inbox-sidebar-header-status-connected div {
  background-color: var(--success);
  border: 4px solid var(--success-pale);
}
.inbox-sidebar-header-status-disconnected div {
  background-color: var(--error);
  border: 4px solid var(--error-pale);
}
