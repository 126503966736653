.hover-menu-fade-in {
  opacity: 0;
  transform: translate(0, 4px) scale(0.98);
  transition: all 0.08s ease-in-out;
  pointer-events: none;
}
.hover-menu-fade-in.visible {
  opacity: 1;
  transform: translate(0, 0px) scale(1);
  pointer-events: all !important;
}

.hover-menu-container.visible {
  pointer-events: all !important;
}
.hover-menu-container.hidden {
  pointer-events: none !important;
}

.hover-menu-container {
  position: absolute;
  width: 110%;
  min-width: 216px;
  padding: 4px;
  z-index: 5;
}
.hover-menu-container-top-left {
  bottom: calc(100%);
  left: -4px;
}
.hover-menu-container-top-right {
  bottom: calc(100%);
  right: 4px;
}
.hover-menu-container-bottom-left {
  top: calc(100%);
  left: -4px;
}
.hover-menu-container-bottom-right {
  top: calc(100%);
  right: 4px;
}

.hover-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--white);
  border-radius: var(--border-input);
  border: 1px solid var(--border);
  box-shadow: var(--shadow);
  gap: 4px;
  padding: 4px;
  overflow: hidden;
}

.hover-menu-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  gap: 6px;
  height: 40px;
  padding: 0 16px;
  padding-right: 40px;
  border-radius: var(--border-input);
  cursor: pointer;
}
.hover-menu-option:hover {
  background-color: var(--pale);
}

.hover-menu-option-small {
  height: 32px;
  padding: 0 12px;
}

.hover-menu-option-icon {
  margin-left: -8px;
}
