.inbox-details-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  margin-top: -8px;
}
.inbox-details-info textarea {
  height: 80px !important;
}
.inbox-details-info button {
  position: static;
  margin-top: 0px !important;
}
