.list-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  padding-left: 4px;
  gap: 2px;
  border-radius: var(--border-tag);
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
}
.list-tag-border {
  border: 1px solid;
}
.list-tag.blue {
  background-color: var(--tag-blue-pale);
  border-color: var(--tag-blue);
  color: var(--tag-blue);
}
.list-tag.green {
  background-color: var(--tag-green-pale);
  border-color: var(--tag-green);
  color: var(--tag-green);
}
.list-tag.orange {
  background-color: var(--tag-orange-pale);
  border-color: var(--tag-orange);
  color: var(--tag-orange);
}
.list-tag.purple {
  background-color: var(--tag-purple-pale);
  border-color: var(--tag-purple);
  color: var(--tag-purple);
}
.list-tag.red {
  background-color: var(--tag-red-pale);
  border-color: var(--tag-red);
  color: var(--tag-red);
}
.list-tag.yellow {
  background-color: var(--tag-yellow-pale);
  border-color: var(--tag-yellow);
  color: var(--tag-yellow);
}
.list-tag.pink {
  background-color: var(--tag-pink-pale);
  border-color: var(--tag-pink);
  color: var(--tag-pink);
}

.list-tag-close {
  display: none;
}
.list-tag:hover .list-tag-close {
  display: flex;
}
.list-tag:hover .list-tag-icon {
  display: none;
}
.list-tag-hide {
  display: none;
}
