.assignee-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.assignee-card-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.assignee-card__image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--border);
  overflow: hidden;
}

.assignee-card__info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.assignee-card__info__top {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
