.sheet {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 1000;
}
.sheet-open {
  pointer-events: auto;
}

.sheet-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--opacity);
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  pointer-events: none;
}
.sheet-overlay-open {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.sheet-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 800px;
  padding: 24px;
  background: var(--white);
  box-shadow: var(--shadow);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  pointer-events: auto;
}
.sheet-content-open {
  transform: translateX(0);
}

.sheet-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
