.inbox-conversation-date {
  position: sticky;
  top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 12px;
  z-index: 5;
}

.inbox-conversation-date span {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--pale);
  border-radius: var(--border-input);
  padding: 2px 24px;
  z-index: 1;
}

.inbox-conversation-date-line {
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: var(--border);
}

.inbox-conversation-date-pinned span {
  box-shadow: var(--shadow);
}
.inbox-conversation-date-pinned .inbox-conversation-date-line {
  opacity: 0;
}
