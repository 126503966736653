.icon-tag {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  padding-left: 4px;
  gap: 2px;
  border-radius: var(--border-tag);
}
