.organization-member-avatar {
  position: relative;
}
.organization-member-avatar img {
  border-radius: 50%;
  border: 1px solid var(--border);
}
.organization-member-avatar div {
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  outline: 3px solid var(--white);
  z-index: 1;
}
.oma-online div {
  background-color: var(--active);
}
.oma-offline div {
  background-color: var(--pale);
}
