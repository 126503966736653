.campaign-results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.campaign-results-header-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.campaign-results-header-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.campaign-results-header-left-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-pale);
  border-radius: var(--border-input);
  width: 32px;
  height: 32px;
}

.campaign-results-header-right .button {
  margin-top: 0px;
}

.campaign-results-table {
  margin-top: 16px;
  margin-bottom: 32px;
}
