.campaign-funnel-graph-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
  height: 232px;
  padding: 16px;
}

.campaign-funnel-graph-labels {
  flex: auto;
  display: flex;
  justify-content: flex-start;
}
.campaign-funnel-graph-labels div {
  flex: 1;
}

.campaign-funnel-graph-plot {
  height: 180px;
}
