.inbox-sidebar {
  display: flex;
  flex-direction: column;
  width: 180px;
  border-right: 1px solid var(--border);
}

.inbox-sidebar-header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 12px;
  margin: 8px;
  border: 1px solid var(--border);
  border-radius: var(--border-input);
}

.inbox-sidebar-body {
  overflow-y: auto;
}
