.plan-cycle-chart {
  height: 320px;
  margin-top: 16px;
}

.plan-cycle-chart-tooltip {
  border-radius: var(--border-input);
  background-color: var(--white);
  box-shadow: var(--shadow);
  padding: 8px 12px;
}
.plan-cycle-chart-tooltip > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
