.chatbot-integrations {
  display: flex;
  flex-direction: column;
}

.chatbot-integrations__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  gap: 12px;
}

.chatbot-integrations__header__id {
  background-color: var(--pale);
  padding: 4px 8px;
  border-radius: 8px;
  overflow-x: auto;
}

.chatbot-integrations__body {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(246px, 1fr));
  grid-gap: 16px;
}
